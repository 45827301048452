import React from 'react';
import { useNavigate } from 'react-router-dom';

const ErrorPage = ({ onTryAgain }) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-prompt-bg text-prompt-white">
      <h1 className="text-4xl font-bold mb-4">Oops! Something went wrong</h1>
      <p className="text-xl mb-8">We're sorry, but an error has occurred.</p>
      <div className="space-x-4">
        <button
          onClick={onTryAgain}
          className="px-4 py-2 bg-prompt-secondary text-prompt-white rounded hover:bg-prompt-secondary-dark transition-colors"
        >
          Try Again
        </button>
        <button
          onClick={() => navigate('/')}
          className="px-4 py-2 bg-prompt-primary text-prompt-white rounded hover:bg-prompt-primary-dark transition-colors"
        >
          Go back to home
        </button>
      </div>
    </div>
  );
};

export default ErrorPage;