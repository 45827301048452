const isProduction = process.env.NODE_ENV === 'production';

const sanitizeArg = (arg) => {
  if (typeof arg === 'object' && arg !== null) {
    return JSON.stringify(arg, (key, value) => {
      if (key.toLowerCase().includes('token') || key.toLowerCase().includes('password')) {
        return '[REDACTED]';
      }
      return value;
    }, 2);
  }
  return arg;
};

const logger = {
  log: (...args) => {
    if (!isProduction) {
      console.log(...args.map(sanitizeArg));
    }
  },
  info: (...args) => {
    if (!isProduction) {
      console.info(...args.map(sanitizeArg));
    }
  },
  warn: (...args) => {
    if (!isProduction) {
      console.warn(...args.map(sanitizeArg));
    }
  },
  error: (...args) => {
    // Always log errors, but sanitize them in production
    if (isProduction) {
      console.error('An error occurred:', args.map(arg => 
        typeof arg === 'object' ? sanitizeArg(arg) : arg
      ));
    } else {
      console.error(...args.map(sanitizeArg));
    }
  }
};

module.exports = logger;