import React, { createContext, useState, useEffect, useCallback, useContext, useRef } from 'react';
import { createClient } from '@supabase/supabase-js';
import { 
  onAuthStateChanged, 
  signOut, 
  signInWithEmailLink
} from "firebase/auth";
import axios from 'axios';
import { debounce } from 'lodash';
import logger from './utils/logger';
import { auth } from './firebaseConfig';
import { cleanupLocalStorage } from './utils/localStorageUtils';

const AuthContext = createContext();

const supabase = createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_ANON_KEY);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authToken, setAuthToken] = useState(null);
  const [userCredits, setUserCredits] = useState(null);
  const [userPresets, setUserPresets] = useState([]); 
  const [presetsLoaded] = useState(false);
  const [freeCredits, setFreeCredits] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const lastFetchTime = useRef(0);
  const fetchInterval = 5 * 60 * 1000;
  const creditCache = useRef(null);
  
  
  
  
  
  //Get firebase token
  const getFirebaseToken = useCallback(async () => {
    try {
      const user = auth.currentUser;
      if (!user) {
        logger.log('No authenticated user');
        return null;
      }
      const token = await user.getIdToken(true);
      return token;
    } catch (error) {
      logger.error('Error getting Firebase token:', error);
      return null;
    }
  }, []);













  
  
  
  
  
  
  
// Fetch user credits
const fetchUserCredits = useCallback(async (forceUpdate = false) => {
  
  const token = await getFirebaseToken();
  if (!token) {
    logger.log('No token provided, cannot fetch credits');
    return null;
  }

  logger.log(`Current token: ${token.substring(0, 10)}...`);

  const now = Date.now();
  if (!forceUpdate && now - lastFetchTime.current < fetchInterval) {
    logger.log(`Using cached credits`);
    return creditCache.current;
  }

  try {
    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001';
    logger.log(`Fetching credits from: ${apiUrl}/api/user-credits`);
    const response = await axios.get(`${apiUrl}/api/user-credits`, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    
    logger.log('Credit fetch response:', response);
    
    if (response.status === 200) {
      const data = response.data;
      logger.log('Received credit data:', data);
      setUserCredits(data.token_balance || 0);
      setFreeCredits(data.free_credits || 0);
      lastFetchTime.current = now;
      creditCache.current = { 
        userCredits: data.token_balance || 0, 
        freeCredits: data.free_credits || 0
      };
      return creditCache.current;
    }
  } catch (error) {
    logger.error('Error fetching user credits:', error);
    logger.error('Error details:', error.response?.data);
  }
  return null;
}, [fetchInterval, getFirebaseToken, setUserCredits, setFreeCredits]);





  
  // Fetch user presets
  const fetchUserPresets = useCallback(async () => {
    const token = await getFirebaseToken();
    if (!token) {
      logger.log('No token available, cannot fetch presets');
      return [];
    }

    try {
      const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001';
      logger.log(`Fetching presets from: ${apiUrl}/api/preset`);
      const response = await axios.get(`${apiUrl}/api/preset`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      
      logger.log('Preset fetch response:', response);
      
      if (response.status === 200 && Array.isArray(response.data)) {
        setUserPresets(response.data);
        return response.data;
      } else {
        logger.warn('Unexpected response format for presets:', response.data);
        setUserPresets([]);
        return [];
      }
    } catch (error) {
      logger.error('Error fetching user presets:', error);
      logger.error('Error details:', error.response?.data);
      setUserPresets([]);
      return [];
    }
  }, [getFirebaseToken]);

  
  
  
  
  
  useEffect(() => {
    if (isAuthenticated && !presetsLoaded) {
      fetchUserPresets();
    }
  }, [isAuthenticated, presetsLoaded, fetchUserPresets]);
  
  
  
  const debouncedFetchUserCredits = useCallback(
    debounce((forceUpdate) => fetchUserCredits(forceUpdate), 5000, { leading: true, trailing: false, maxWait: 10000 }),
    [fetchUserCredits]
  );




// logout
  const logout = useCallback(async () => {
    try {
      await signOut(auth);
      setIsAuthenticated(false);
      setAuthToken(null);
      setUserCredits(null);
      setFreeCredits(null);
      localStorage.removeItem('authToken');
      cleanupLocalStorage();
    } catch (error) {
      logger.error('Error during logout:', error);
    }
  }, []);






  
  //handle email signin
  const handleEmailLinkSignIn = useCallback(async (email, href) => {
    try {
      const result = await signInWithEmailLink(auth, email, href);
      const user = result.user;
      const token = await user.getIdToken();
      setIsAuthenticated(true);
      setAuthToken(token);
      setIsEmailVerified(true);
      localStorage.setItem('authToken', token);
      await debouncedFetchUserCredits(true);
      await fetchUserPresets();
      return token;
    } catch (error) {
      logger.error('Error signing in with email link:', error);
      throw error;
    }
  }, [debouncedFetchUserCredits, fetchUserPresets]);
  
  
  
  
  
  
  
  
  
  
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          setIsEmailVerified(user.emailVerified);
          const token = await getFirebaseToken();
          setIsAuthenticated(true);
          setAuthToken(token);
          localStorage.setItem('authToken', token);
          await debouncedFetchUserCredits(true);
          await fetchUserPresets();
        } catch (error) {
          logger.error('Error getting user token or fetching presets:', error);
          setIsAuthenticated(false);
        }
      } else {
        setIsAuthenticated(false);
        setAuthToken(null);
        setUserCredits(null);
        setFreeCredits(null);
        setUserPresets([]);
        setIsEmailVerified(false);
        localStorage.removeItem('authToken');
      }
      setIsLoading(false);
    });
  
    return () => unsubscribe();
  }, [debouncedFetchUserCredits, getFirebaseToken, fetchUserPresets]);






  const value = {
    isAuthenticated,
    authToken,
    userCredits,
    freeCredits,
    userPresets,
    presetsLoaded,
    isLoading,
    supabase,
    fetchUserCredits: debouncedFetchUserCredits,
    isEmailVerified,
    handleEmailLinkSignIn,
    setIsEmailVerified,
    setIsAuthenticated,
    setUserCredits,
    setFreeCredits,
    logout,
    setUserPresets: (newPresetsOrUpdateFunction) => {
      setUserPresets((prevPresets) => {
        if (typeof newPresetsOrUpdateFunction === 'function') {
          return newPresetsOrUpdateFunction(prevPresets || []);
        } else if (Array.isArray(newPresetsOrUpdateFunction)) {
          return newPresetsOrUpdateFunction;
        } else {
          logger.warn('Attempted to set userPresets with invalid value:', newPresetsOrUpdateFunction);
          return prevPresets || [];
        }
      });
    },
    fetchUserPresets,
    getFirebaseToken,
    auth 
  };
  
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
  };
  
  export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
      throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
  };