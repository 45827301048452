import React from 'react';
import { CheckCircle, XCircle, AlertCircle } from 'lucide-react';

const Notification = ({ message, type = 'info', isVisible }) => {
  const getIcon = () => {
    switch (type) {
      case 'success':
        return <CheckCircle className="text-prompt-white" size={34} />;
      case 'error':
        return <XCircle className="text-white" size={34} />;
      default:
        return <AlertCircle className="text-prompt-white" size={34} />;
    }
  };

  const getBgColor = () => {
    switch (type) {
      case 'success':
      case 'info':
        return 'bg-prompt-secondary-color';
      case 'error':
        return 'bg-prompt-red';
      default:
        return 'bg-prompt-secondary-color';
    }
  };

  const getTextColor = () => {
    return type === 'error' ? 'text-white' : 'text-prompt-white';
  };

  return (
    <div 
      className={`${getBgColor()} border border-prompt-border rounded-l-lg p-4 shadow-lg max-w-sm 
                   transition-all duration-300 ease-in-out
                   ${isVisible ? 'animate-slide-in-out' : 'opacity-0 translate-x-full'}`}
    >
      <div className="flex items-center">
        <div className="flex-shrink-0">{getIcon()}</div>
        <div className={`${getTextColor()} ml-3`}>{message}</div>
      </div>
    </div>
  );
};

export default Notification;